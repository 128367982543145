import React from "react";

import { Modal, Box, Typography, IconButton } from "@mui/material";
import Frame from "../../assets/images/Frame.svg";
import closeIcon from "../../assets/images/closeIcon.svg";
import { useState } from "react";
import { getCookie } from "./cookieUtils";
import { useAppDispatch } from "Redux/App/hooks";
import { CloseSuccessPage } from "Redux/feature/StudentHome";
interface SuccessPopupProps {
  open: boolean;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({ open }) => {
  const [isOpen, setIsOpen] = useState(open);
  const user_id = getCookie("id");
  const dispatch = useAppDispatch();


  const handleClose = () => {
    if (user_id) {
      setIsOpen(false);
      dispatch(
        CloseSuccessPage({
          student_id: user_id,
          model: "additional_questions_popup",
          is_close: 1,
        })
      );
    }
  };
  
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "75%", sm: "80%", md: "60%", lg: "50%" },
            maxWidth: "650px",
            height: "auto", // Adjust height dynamically
            maxHeight: "90vh", // Prevent overflow on small screens
            bgcolor: "white",
            boxShadow: 24,
            p: 3,
            textAlign: "center",
            borderRadius: "40px",
          }}
        >
          {" "}
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              left: 15,
              width: "35px",
              height: "35px",
              p: 0,
              backgroundColor: "transparent",
              borderRadius: "50%",
              "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
            }}
          >
             <img
                          src={closeIcon}
                          alt="Close Eyes Icon"
                          loading="lazy"
                          title="Close Eyes Icon"
                        // style={{ width: "100%", height: "100%" }}
                        />
          </IconButton>
          <img
            src={Frame}
            alt="Success"
            style={{
              width: "85%", // Adjusted to fit within modal
              maxWidth: "270px",
              margin: "0 auto",
              display: "block",
              marginTop: "40px",
              marginBottom: "50px",
              transform: "scale(1.1)", // Slightly enlarges the image
            }}
          />
          {/* Success Message */}
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              mt: 2,
              fontSize: { xs: "18px", sm: "22px", md: "24px" }, // Responsive font size
            }}
          >
            כל הכבוד סיימת את כל הנושאים בגיאומטריה!
          </Typography>
          {/* Subtext */}
          <Typography
            sx={{
              mt: "10px",
              color: "gray",
              paddingX: "20px",
              fontSize: { xs: "14px", sm: "16px" }, // Responsive text size
            }}
          >
            לדולר סיט אמט, קונסקקטור אדיפיסינג אלית קולורס מונפרד
            אדנום סילקוף, מרגשי ומרגש. עמחליף הועניב היושב שערש שמחויט - שלושע
            ותלברו חשלויסינג ערשפ.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default SuccessPopup;
