import { Close } from "@mui/icons-material";
import { Box, Button, Container, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import BookIcon from "../../assets/images/BookIcon.png";
import CelebrationGraphics from "../../assets/images/celebration-graphics.svg";
import OctopusSunglass from "../../assets/images/octopus-sunglass.svg";
import {
  setDynamicSessionIcon,
  setOpenAssignmentSuccess,
} from "Redux/feature/StudentHome";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import { useSearchParams } from "react-router-dom";
import { getImageUrl } from "hooks/imageUrl";

const octopusModalView = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "787px",
  maxWidth: "calc(100% - 30px)",
  background: "#F8F9FA",
  border: "1px solid #D0D4D9",
  boxShadow: 24,
  pt: "5px",
  pb: "30px",
  borderRadius: "30px",
  minWidth: "1px",
};

type Props = { openAssignmentSuccess: any };

const AssignmentDialog = ({ openAssignmentSuccess }: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [currentIcon, setCurrentIcon] = useState("");

  // const [openAssignmentSuccess, setOpenAssignmentSuccess] =
  //   React.useState(false);

  const handleCloseAssignmentSuccess = () =>
    dispatch(setOpenAssignmentSuccess(false));

  let atomicUnitData: any = useAppSelector(
    (state) => state.StudentsHomeData.atomicUnitsData
  );

  const assignment_id: any = searchParams?.get("assignment_id");
  const meetingIndex = atomicUnitData.findIndex((item: any) => {
    return item.meeting.assignment.some(
      (assignmentData: any) => assignmentData.id == assignment_id
    );
  });
  const progress = atomicUnitData[meetingIndex]?.meeting?.assignment;

  const assignmentModal: boolean = useAppSelector(
    (state) => state.StudentsHomeData.openAssignmentSuccess
  );

  useEffect(() => {
    const matchingObject = progress?.find(
      (item: any) => item.id == assignment_id
    );

    if (matchingObject && matchingObject.icon !== null) {
      dispatch(
        setDynamicSessionIcon({
          name: matchingObject.icon.name,
          icon1: matchingObject.icon.icon1,
          icon2: matchingObject.icon.icon2,
        })
      );
    }
  }, [assignmentModal, progress, assignment_id]);

  const sessionIcone1 = useAppSelector(
    (state) => state.StudentsHomeData.sessionIcons.icon1
  );

  const sessionIcone2 = useAppSelector(
    (state) => state.StudentsHomeData.sessionIcons.icon2
  );

  useEffect(() => {
    setCurrentIcon(sessionIcone2 ?? ""); // Use the nullish coalescing operator to handle null
    const timer = setTimeout(() => {
      if (sessionIcone1 !== "" && sessionIcone1 !== null) {
        setCurrentIcon(sessionIcone1);
      } else if (sessionIcone2 !== "") {
        setCurrentIcon(sessionIcone2 ?? "");
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [sessionIcone1, sessionIcone2]);

  return (
    <div>
      <Modal
        open={openAssignmentSuccess}
        onClose={handleCloseAssignmentSuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: "101" }}
      >
        <Box
          sx={octopusModalView}
          className="modal-parent modal-session"
          style={{ overflow: "hidden" }}
        >
          <Button
            className="modal-close"
            sx={{ position: "absolute", zIndex: "99", left: "0", top: "6px" }}
          >
            <Close onClick={handleCloseAssignmentSuccess} />
          </Button>
          <img
            src={CelebrationGraphics}
            alt="icon"
            loading="lazy"
            title="icon"
            className="celebration-graphics"
            style={{
              width: "100%",
              maxWidth: "600px",
              marginBottom: "40px",
              position: "absolute",
              bottom: "280px",
              left: "55%",
              transform: "translateX(-50%)",
              zIndex: "-1",
            }}
          />
          <Container sx={{ px: "0" }}>
            <Box>
              <Box className="progress-circle">
                <Box className="progress"></Box>
              </Box>

              <Box className="progress-circle">
                <svg className="svg" width="200" height="200">
                  <circle 
                    cx="100"
                    cy="100"
                    r="80"
                    stroke="#33CD75"
                    strokeWidth={11}
                    fill="white" 
                  ></circle>
                </svg>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    maxWidth: "80px",
                    position: "relative",
                    top: "-145px",
                    left: "-58px",
                  }}
                >
                    <Box
                sx={{
                  position: "fixed",
                  top: { xs: "42px", md: "59px" },
                  left: { xs: "26%", md: "27%"},
                }}
              >
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="18"
                    cy="18"
                    r="16.5"
                    fill="#33CD75"
                    stroke="white"
                    stroke-width="3"
                  />
                  <path
                    d="M23.3332 14L15.9998 21.3333L12.6665 18"
                    stroke="#F3FCF7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
                  <img
                    src={currentIcon ? getImageUrl(currentIcon, "") : BookIcon}
                    alt="icon"
                    loading="lazy"
                    title="icon"
                    style={{
                      width: "100%",
                      maxWidth: "200px",
                      marginBottom: "0",
                    }}
                  />
                </Box>
              </Box>

              <Box
                className="modal-session-content-img"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  maxWidth: { xs: "180px", sm: "320px", md: "400px" },
                  m: { xs: "30px auto 15px", sm: "40px auto 0" },
                }}
              >
                <img
                  src={OctopusSunglass}
                  alt="icon"
                  loading="lazy"
                  title="icon"
                  style={{
                    width: "100%",
                    maxWidth: "285px",
                    marginBottom: "40px",
                    position: "relative",
                    right: "-70px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  pt: { xs: "20px", lg: "0" },
                  pl: { xs: "20px", lg: "0" },
                  pr: { xs: "20px", lg: "0" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: "24px", lg: "32px" },
                    textAlign: "center",
                  }}
                >
                  <FormattedMessage id="assignment-success-modal-title" />
                </Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  <FormattedMessage id="assignment-success-modal-text" />
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      </Modal>
    </div>
  );
};

export default AssignmentDialog;
